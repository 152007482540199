<template>
    <div>
        <template v-if="isEditable">
            <div class="row mb-3">
                <div class="col-md-9 col-sm-12 p-1">
                    <input id="place-input" class="place-input mb-2" type="text" v-model="placeInput"
                        :placeholder="$t('searchPlaces')" />
                </div>
                <div class="col-md-3 col-sm-12 p-1">
                    <CButton @click="findPlace(placeInput)" style="padding: 10px;font-size: 16px;" block
                        color="success">{{
            $t('search') }}</CButton>
                </div>
            </div>
            <div @click="setPlaceList()" v-if="placeInput.length > 0 && !isClicked && foundPlace.name"
                class="found-place">
                <i class="fi fi-rr-marker mr-2  h5"></i>
                <div>
                    <h5> {{ foundPlace.name }} </h5>{{ foundPlace.formatted_address }}
                </div>
            </div>
        </template>
        <gmap-map :class="pickerStyle" :center="center" :zoom="zoom" :options="mapOptions" @click="placeMarker">
            <gmap-marker v-if="markerPosition" :position="markerPosition" :clickable="true" :draggable="isEditable"
                @dragend="updatePosition" />
        </gmap-map>
    </div>
</template>

<script>
export default {
    props: {
        isEditable: {
            type: Boolean,
            default: true,
        },
        selectedLocation: {
            type: Object,
            default: () => ({ lat: 0, lng: 0 }),
        },
    },
    data() {
        return {
            center: { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng },
            zoom: 16,
            markerPosition: { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng },
            service: null, // Reference to the Places service
            foundPlace: {},
            isClicked: false,
            placeInput: ''
        };
    },
    computed: {
        pickerStyle() {
            return this.isEditable ? 'picker-style' : 'picker-style-show';
        },
        mapOptions() {
            return {
                zoomControl: this.isEditable,
                streetViewControl: this.isEditable,
                mapTypeControl: this.isEditable,
                fullscreenControl: this.isEditable,
                draggable: this.isEditable,
                disableDefaultUI: !this.isEditable,
            };
        },
    },
    mounted() {
        this.setUserLocation();
        this.$nextTick(() => {
            this.initPlaceSearch();
        });
    },
    methods: {
        setUserLocation() {
            if (this.isEditable) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const { latitude, longitude } = position.coords;
                        this.center = { lat: latitude, lng: longitude };
                        this.markerPosition = { lat: latitude, lng: longitude };
                        this.emitLocation();
                    }, (error) => {
                        console.error("Error getting user location:", error);
                        this.setFallbackLocation();
                    });
                } else {
                    this.setFallbackLocation();
                }
            }
        },
        setFallbackLocation() {
            const lat = 13.7563;
            const lng = 100.5018;
            this.center = { lat, lng };
            this.markerPosition = { lat, lng };
            this.emitLocation();
        },
        placeMarker(event) {
            if (this.isEditable) {
                this.markerPosition = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
                this.emitLocation();
            }
        },
        updatePosition(event) {
            if (this.isEditable) {
                this.markerPosition = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                };
                this.emitLocation();
            }
        },
        emitLocation() {
            this.$emit('location-selected', this.markerPosition);
        },
        initPlaceSearch() {
            const input = document.getElementById('place-input');
            if (input) {
                input.addEventListener('keydown', (e) => {
                    if (input.value.length > 3) {
                        this.isClicked = false
                        this.getPlaceLists(input.value)
                    }
                    if (e.key === 'Enter') {
                        this.isClicked = false
                        this.findPlace(input.value);
                    }
                });
            }
        },
        setPlaceList() {
            const place = this.foundPlace
            if (place && place.geometry) {
                this.center = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                this.markerPosition = this.center;
                this.emitLocation();
                this.isClicked = true
            } else {
                console.error('Place has no geometry information.');
            }
        },
        getPlaceLists(query) {
            let service = new google.maps.places.PlacesService(document.createElement('div'));
            const request = {
                query: query,
                fields: [
                    'name',
                    'geometry',
                    'formatted_address',
                ],
            };
            service.findPlaceFromQuery(request, (results, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    this.foundPlace = results[0]

                } else {
                    console.error('Place search failed:', status);
                }

            });
        },
        findPlace(query) {
            if (query.length > 0) {
                if (!this.service) {
                    this.service = new google.maps.places.PlacesService(document.createElement('div'));
                }
                const request = {
                    query: query,
                    fields: ['name', 'geometry'],
                };
                this.service.findPlaceFromQuery(request, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {

                        const place = results[0];
                        if (place && place.geometry) {
                            this.center = {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                            };
                            this.markerPosition = this.center;
                            this.emitLocation();
                        } else {
                            console.error('Place has no geometry information.');
                        }
                    } else {
                        console.error('Place search failed:', status);
                    }

                });
            }
        }
    },
};

</script>

<style>
.picker-style {
    width: 100%;
    height: 60vh;
}

.picker-style-show {
    width: 100%;
    aspect-ratio: 1/0.5;
    border-radius: 0.25rem;
    overflow: hidden;
    border: 1px solid #d8dbe0;
}

.gm-style .gm-style-iw-tc {
    visibility: hidden;
}

.gm-style .gm-style-iw-c {
    visibility: hidden;
}

.place-input {
    padding: 10px;
    width: 100%;
    font-size: 16px;
    border: 1px solid #d8dbe0;
    border-radius: 4px;
    color: var(--dark);
}

.found-place {
    border-radius: 8px;
    padding: 2cap;
    margin-bottom: 4cap;
    border: 1px solid #eeeeee;
    color: var(--dark);
    display: flex;
}
</style>
