<template>
  <div v-if="isPermission">
    <div v-if="isEditShop == true">
      <CAlert color="danger" id="shopSettingAlertDanger" v-if="validatedata != ''" class="mt-3">
        {{ validatedata }}
      </CAlert>
      <CAlert color="success" id="shopSettingAlertSuccess" v-if="isSuccess === true" class="mt-3">
        {{ titlesuccess }}
      </CAlert>
      <CRow>
        <CCol md="12" class="text-center">
          <div class="d-inline-flex align-items-start">
            <h2 id="shopSettingHeaderTitle" class="font-weight-normal text-dark">
              {{ datashop.shopName }} - {{ datashop.branchName }}
            </h2>
            <HelpButton :isHeader="false" class="ml-2 mt-2" id="shopSettingHelpButton"></HelpButton>
          </div>
          <h6 class="text-description" id="shopSettingDescription">
            {{ $t("manageShop") }}
          </h6>
        </CCol>
      </CRow>
      <hr />
      <CRow class="mt-3">
        <CCol md="6" style="padding:5%;">
          <p class="text-left font-weight-normal" id="shopSettingInformation">
            {{ $t("information") }}
          </p>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingLogoText" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("logo") }}
                  </span>
                </label>
                <div class="col-sm-9 form-group text-center">
                  <!-- แทน input file ที่แสดงภาพ -->
                  <input
                    id="shopSettingLogoInputFile"
                    type="file"
                    ref="fileInput"
                    style="display: none"
                    @change="onFileSelect"
                  />
                  <img
                    :src="imageUrl || datashop.remoteImagePath"
                    style="width: 60%; cursor: pointer"
                    @click="triggerFileInput"
                    class="img-fluid"
                    id="shopSettingLogoImage"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-dark"> </label>
                <div class="col-sm-9 form-group">
                  <input
                    id="shopSettingLogoInputFile"
                    type="file"
                    class="text-dark custom-file-input form-control"
                    required
                    @change="onFileSelect"
                  />
                  <label
                    id="shopSettingSelectedFileLabel"
                    class="custom-file-label text-left"
                    style="margin-right: 7px; margin-left: 7px"
                  >
                    {{ $t("selectedFile") }}
                    <span style="font-size: 10px" class="text-info">
                      ({{ $t("fileSize") }})
                    </span>
                  </label>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingShopnameLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("shopname") }}
                    <span class="text-danger font-weight-normal">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput id="shopSettingShopnameInput" class="text-dark" v-model="datashop.shopName" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingBrancenameLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("branchName") }}
                    <span class="text-danger font-weight-normal">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput id="shopSettingBrancenameInput" class="text-dark" v-model="datashop.branchName" />
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingBussinesstypeLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("businessType") }}
                    <span class="text-danger font-weight-normal">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <div class="form-group">
                    <select class="custom-select" id="shopSettingBussinesstypeSelect" v-model.number="datashop.businessType">
                      <option selected>{{ $t("selectedShoptype") }}</option>
                      <option value="0">{{ $t("businessType1") }}</option>
                      <option value="1">{{ $t("businessType2") }}</option>
                      <option value="2">{{ $t("businessType3") }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingTaxIDLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("taxID") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput id="shopSettingTaxIDInput" class="text-dark" v-model="datashop.taxId" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingVatLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("vat") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CRow>
                    <CCol col="4">
                      <div
                        class="form-group custom-control custom-checkbox"
                        style="padding-top: 7px"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input success cursor"
                          v-model="datashop.isVATIncluded"
                          id="isVAT"
                        />
                        <label class="custom-control-label cursor" for="isVAT">
                          {{ $t("exclusive") }}
                        </label>
                      </div>
                    </CCol>
                    <CCol col="7">
                      <CInput id="shopSettingVatInput" class="text-dark" v-model="datashop.VAT" />
                    </CCol>
                    <CCol col="1" style="padding-top: 7px"> % </CCol>
                  </CRow>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingServiceChargeLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("serviceCharge") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput id="shopSettingServiceChargeInput" class="text-dark" v-model="datashop.serviceCharge" />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="6" style="padding: 5%">
          <p class="text-left font-weight-dark">
            {{ $t("shopopen") }}
            <CAlert
              color="info"
              class="d-inline-block mt-2"
              style="font-size: 14px; padding: 8px 12px"
              id="shopSettingAlertInfo"
            >
              <i class="fi fi-rr-info mr-1"></i> {{ $t("changeOpenClose") }}
            </CAlert>
          </p>
          <CRow>
            <CCol md="6" xs="12" sm="6" col="12">
              <div class="form-group row">
                <label id="shopSettingOpenTimeLabel" class="col-sm-4 col-4 col-form-label text-left text-dark">
                  <span>
                    {{ $t("open") }}
                  </span>
                </label>
                <div class="form-group col-sm-8 col-8">
                  <vue-timepicker
                    id="shopSettingOpenTimePicker"
                    input-class="form-control"
                    v-model="datashop.open"
                  ></vue-timepicker>
                </div>
              </div>
            </CCol>
            <CCol md="6" xs="12" sm="6" col="12">
              <div class="form-group row">
                <label id="shopSettingClosedTimeLabel" class="col-sm-4 col-4 col-form-label text-left text-dark">
                  <span>
                    {{ $t("closed") }}
                  </span>
                </label>
                <div class="col-sm-8 col-8 form-group">
                  <vue-timepicker
                    input-class="form-control"
                    v-model="datashop.close"
                    width="100%"
                    id="shopSettingClosedTimePicker"
                  ></vue-timepicker>
                </div>
              </div>
            </CCol>
          </CRow>
          <hr />
          <p class="text-left font-weight-normal">
            {{ $t("address") }}
          </p>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingAddress1Label" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("address1") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput id="shopSettingAddress1Input" class="text-dark" v-model="datashop.address1" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row" style="">
                <label id="shopSettingAddress2Label" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("address2") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-dark" id="shopSettingAddress2Input" v-model="datashop.address2" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label id="shopSettingTelLabel" class="col-sm-3 col-form-label text-left text-dark">
                  <span>
                    {{ $t("tel") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput id="shopSettingTelInput" class="text-dark" v-model="datashop.tel" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
                  <p class="m-0">
                    {{ $t("pinShopLocation") }}
                  </p>
                  <CAlert
                      color="info"
                      class="d-inline-block mt-2"
                      style="font-size: 14px; padding: 8px 12px"
                      id="shopSettingAlertLocation"
                    >
                      <i class="fi fi-rr-info mr-1"></i> {{ $t("addressDesc") }}
                    </CAlert>
                    <div class="form-group row" id="shopSettingLocationPickerFormGroup">
                      <div class="col-sm-12" id="shopSettingLocationPickerCol">
                        <div
                          @click="locationPickerModal = true"
                          style="width: 100%"
                          id="shopSettingLocationPickerDiv"
                        >
                          <div
                            v-if="locationModel.lat !== 0"
                            style="width: 100%; position: relative"
                            id="shopSettingLocationPickerMapDiv"
                          >
                            <location-picker
                              :key="'map' + locationPickerKey"
                              :isEditable="false"
                              :selectedLocation="locationModel"
                              id="shopSettingLocationPickerComponent"
                            />
                            <!-- Disabled Overlay -->
                            <div class="overlay" id="shopSettingLocationPickerOverlay">
                              <!-- Overlay content or just a transparent overlay -->
                            </div>
                          </div>
                          <div v-else id="shopSettingLocationPickerNoLocationDiv">
                            <div class="form-group" id="shopSettingNoLocationFormGroup">
                              <div class="input-group mb-2 mr-sm-2" id="shopSettingNoLocationInputGroup">
                                <div class="input-group-prepend" id="shopSettingNoLocationInputGroupPrepend">
                                  <div class="input-group-text" id="shopSettingNoLocationInputGroupText">
                                    <i class="fa-solid fa-location-dot" id="shopSettingNoLocationIcon"></i>
                                  </div>
                                </div>
                                <input
                                  @click="locationPickerModal = true"
                                  type="text"
                                  class="form-control"
                                  id="shopSettingNoLocationInput"
                                  :placeholder="$t('noLocationPinned')"
                                  readonly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
            </CCol>
          </CRow>
        </CCol>
        <CButton color="success" id="shopSettingConfirmModalTrue" class="btn-block" @click="confirmModal = true" square>
          {{ $t("updateData") }}
        </CButton>
      </CRow>
      <CModal
        :closeOnBackdrop="false"
        :show.sync="locationPickerModal"
        centered
        color="success"
        :title="$t('pinShopLocation')"
        id="shopSettingLocationPickerModal"
      >
        <div id="shopSettingLocationPickerContainer">
          <location-picker 
            @location-selected="handleLocationSelected" 
            id="shopSettingLocationPickerComponent" 
          />
        </div>
        <template #footer>
          <div
            class="d-flex justify-content-center"
            style="width: 100%"
            id="shopSettingLocationPickerFooter"
          >
            <CButton
              style="width: 45%; margin-right: 1cap"
              color="success"
              @click="saveShopLocation()"
              id="shopSettingLocationPickerSubmitButton"
            >
              {{ $t("submit") }}
            </CButton>
            <CButton
              style="width: 45%; margin-left: 1cap"
              color="secondary"
              @click="clearShopLocation()"
              id="shopSettingLocationPickerCancelButton"
            >
              {{ $t("cancel") }}
            </CButton>
          </div>
        </template>
      </CModal>
      <CModal
        :show.sync="confirmModal"
        color="success"
        centered
        headers="false"
        :title="$t('confirmUpdateInformation')"
        :footer="footer"
        id="shopSettingConfirmModal"
      >
        <CRow class="justify-content-center col-md-12" id="shopSettingConfirmModalRow">
          <CRow class="justify-content-center col-md-12" id="shopSettingConfirmModalInnerRow">
            <br id="shopSettingConfirmModalBreakBeforeText" />
            <h4 class="text-center" id="shopSettingConfirmModalText">
              {{ $t("confirmUpdate") }}
            </h4>
            <br id="shopSettingConfirmModalBreakAfterText" />
          </CRow>
        </CRow>
        <template #footer>
          <CRow class="justify-content-center col-md-12 col-12" id="shopSettingConfirmModalFooterRow">
            <CCol col="5" lg="4" id="shopSettingConfirmModalFooterColSave">
              <CButton
                color="success"
                v-if="loading === false"
                block
                v-on:click="savedata"
                id="shopSettingConfirmModalSaveButton"
              >
                {{ $t("save") }}
              </CButton>
              <CButton
                color="success"
                block
                v-else-if="loading === true"
                disabled
                id="shopSettingConfirmModalSaveButtonLoading"
              >
                <CSpinner color="white" size="sm" id="shopSettingConfirmModalSpinner" /> {{ $t("save") }}
              </CButton>
            </CCol>
            <CCol lg="2" col="1" id="shopSettingConfirmModalFooterColSpacer"> </CCol>
            <CCol col="5" lg="4" id="shopSettingConfirmModalFooterColCancel">
              <CButton
                color="light"
                block
                @click="confirmModal = false"
                id="shopSettingConfirmModalCancelButton"
              >
                {{ $t("cancel") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
      <CModal :show.sync="imgModal" centered header="false" :footer="footer" id="shopSettingImgModal">
        <CRow class="justify-content-center col-md-12">
          <CCard
            color="white"
            text-color="dark"
            class="text-center col-md-12"
            body-wrapper
            id="shopSettingImgModalCard"
          >
            <CCardBody color="light" id="shopSettingImgModalCardBoday">
              <VueCropper
                v-show="selectedFile"
                :guides="true"
                :aspectRatio="NaN"
                :scalable="true"
                :zoomable="true"
                :autoCropArea="1"
                :viewMode="2"
                dragMode="move"
                ref="cropper"
                :src="selectedFile"
                alt="Source Image"
                id="shopSettingImgModalVueCropper"
              >
              </VueCropper>
            </CCardBody>
          </CCard>
        </CRow>
        <template #footer>
          <CRow class="justify-content-center col-md-12">
            <CCol col="6">
              <CButton id="shopSettingImgModalSave" color="success" block @click="editImg()">
                {{ $t("save") }}
              </CButton>
            </CCol>
            <CCol col="6">
              <CButton id="shopSettingImgModalCancel" block color="light" @click="imgModal = false">
                {{ $t("cancel") }}
              </CButton>
            </CCol>
          </CRow>
        </template>
      </CModal>
    </div>
    <div v-else>
      <CRow>
        <CCol md="12" class="text-center">
          <h2 class="font-weight-bold text-black">{{ $t("shop") }}</h2>
          <h6 class="text-description">
            {{ $t("manageShop") }}
          </h6>
        </CCol>
      </CRow>
      <hr />

      <CRow class="mt-3">
        <CCol md="6">
          <p class="text-left font-weight-bold">
            {{ $t("information") }}
          </p>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("logo") }}
                  </span>
                </label>
                <div class="col-sm-9 form-group text-center">
                  <img
                    :src="datashop.remoteImagePath"
                    style="width: 60%"
                    class="text-center img-fluid"
                  />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("shopname") }}
                    <span class="text-danger font-weight-bold">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.shopName" disabled />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("branchName") }}
                    <span class="text-danger font-weight-bold">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.branchName" disabled />
                </div>
              </div>
            </CCol>
          </CRow>

          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("businessType") }}
                    <span class="text-danger font-weight-bold">*</span>
                  </span>
                </label>
                <div class="col-sm-9">
                  <div class="form-group">
                    <select
                      class="custom-select"
                      v-model.number="datashop.businessType"
                      disabled
                    >
                      <option selected>{{ $t("selectedShoptype") }}</option>
                      <option value="0">{{ $t("businessType1") }}</option>
                      <option value="1">{{ $t("businessType2") }}</option>
                      <option value="2">{{ $t("businessType3") }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("taxID") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.taxId" disabled />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("vat") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CRow>
                    <CCol col="4">
                      <div
                        class="form-group custom-control custom-checkbox"
                        style="padding-top: 7px"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input success cursor"
                          v-model="datashop.isVATIncluded"
                          id="isVAT"
                          disabled
                        />
                        <label class="custom-control-label cursor" for="isVAT">
                          {{ $t("exclusive") }}
                        </label>
                      </div>
                    </CCol>
                    <CCol col="7">
                      <CInput class="text-black" v-model="datashop.VAT" disabled />
                    </CCol>
                    <CCol col="1" style="padding-top: 7px"> % </CCol>
                  </CRow>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("serviceCharge") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.serviceCharge" disabled />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
        <CCol md="6">
          <p class="text-left font-weight-bold">
            {{ $t("shopopen") }}
          </p>
          <CRow>
            <CCol md="6" xs="12" sm="6" col="12">
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label text-left text-black">
                  <span>
                    {{ $t("open") }}
                  </span>
                </label>
                <div class="form-group col-sm-8 col-8">
                  <vue-timepicker
                    input-class="form-control"
                    v-model="datashop.open"
                    disabled
                  ></vue-timepicker>
                </div>
              </div>
            </CCol>
            <CCol md="6" xs="12" sm="6" col="12">
              <div class="form-group row">
                <label class="col-sm-4 col-4 col-form-label text-left text-black">
                  <span>
                    {{ $t("closed") }}
                  </span>
                </label>
                <div class="col-sm-8 col-8 form-group">
                  <vue-timepicker
                    input-class="form-control"
                    v-model="datashop.close"
                    width="100%"
                    disabled
                  ></vue-timepicker>
                </div>
              </div>
            </CCol>
          </CRow>
          <hr />
          <p class="text-left font-weight-bold">
            {{ $t("address") }}
          </p>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("address1") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.address1" disabled />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row" style="">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("address2") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.address2" disabled />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black">
                  <span>
                    {{ $t("tel") }}
                  </span>
                </label>
                <div class="col-sm-9">
                  <CInput class="text-black" v-model="datashop.tel" disabled />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </div>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import "vue2-datepicker/index.css";
import pos from "@/services/local";
import { mapGetters } from "vuex";
import permis from "@/util/permission";
import LocationPicker from "@/components/LocationPicker";
import HelpButton from "../../containers/HelpButton.vue";

// CSS
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: {
    VueTimepicker,
    VueCropper,
    LocationPicker,
    HelpButton,
  },
  data() {
    return {
      imageUrl: "",
      shopname: "",
      time: null,
      imgModal: false,
      footer: "",
      selectedFile: "",
      cropedImage: "",
      popupModal: false,
      loadingButton: true,
      loading: false,
      confirmModal: false,
      validatedata: "",
      isSuccess: false,
      titlesuccess: "",
      data: [],
      selectedLocation: { lat: 0, lng: 0 },
      locationPickerModal: false,
      locationPickerKey: 0,
      locationModel: { lat: 0, lng: 0 },
    };
  },
  mounted() {
    this.$store.dispatch("getUser");
    this.$store.dispatch("getShopSetting");
    this.$store.dispatch("getAllPOS");
  },
  computed: {
    ...mapGetters(["shops", "users", "date", "shopsetting"]),
    isPermission() {
      return permis.findPermissionRead("setting", this.$route.path);
    },
    isEditShop() {
      return permis.findPermissionEdit("setting", this.$route.path);
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    datashop: {
      get() {
        return this.$store.getters.shopsetting;
      },
      set(newValue) {
        return this.$store.dispatch("setShopSetting", newValue);
      },
    },
  },
  created() {
    this.setLocationModel();
  },
  methods: {
    // ทริกเกอร์การคลิก input file
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    // จัดการเมื่อมีการเปลี่ยนไฟล์
    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageUrl = e.target.result; // เปลี่ยน URL ของภาพเป็นภาพที่เลือก
        };
        reader.readAsDataURL(file);
      }
    },
    clearShopLocation() {
      this.locationPickerModal = false;
    },
    setLocationModel() {
      setTimeout(() => {
        if (this.datashop.location) {
          this.locationModel = this.datashop.location;
          this.locationPickerKey++;
        }
      }, 500);
    },
    handleLocationSelected(location) {
      this.selectedLocation = location;
    },
    saveShopLocation() {
      this.locationPickerKey++;
      this.locationModel = this.selectedLocation;
      this.datashop.location = this.locationModel;
      this.locationPickerModal = false;
    },
    change() {
      this.time = new Date();
    },
    editImg() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.uploadImage(this.cropedImage);
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.imgModal = true;
      this.mime_type = file.type;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    handleImage(e) {
      const file = e.target.files[0];
      this.createBase64Img(file);
    },
    createBase64Img(fileObject) {
      this.url = URL.createObjectURL(fileObject);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.uploadImage(this.image);
      };
      reader.readAsDataURL(fileObject);
    },
    uploadImage(image) {
      const uid = `${localStorage.getItem("shopsUid")}`;
      let data = {
        image: image,
      };
      pos
        .post("/images/v1.0/upload/general/" + uid, data)
        .then((res) => {
          if (res.data.error.code === 0) {
            let baseUrl = res.data.data.baseUrl;
            let url = res.data.data.url;
            this.datashop.remoteImagePath = baseUrl + url;
            this.datashop.logoImageName = ''
            this.imgModal = false;
          } else {
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    savedata() {
      if (!this.datashop.shopName) {
        this.validatedata = this.$i18n.t("pleaseFillShopName");
        return; 
      } else if (!this.datashop.branchName){
        this.validatedata = this.$i18n.t("pleaseFillBranchName");
        return;
      } else if ( this.datashop.businessType === null || this.datashop.businessType === ""){
        this.validatedata = this.$i18n.t("pleaseFillBusinessType")
        return;
      }
      const uid = `${localStorage.getItem("shopsUid")}`;
      this.loading = true;
      const headers = {
        shopObjectId: this.shopObjectId,
      };
      pos({
        method: "post",
        url: "/api/v1.0/" + uid + "/Shop/updateconfig",
        headers: headers,
        data: this.datashop,
      })
        .then(() => {
          setTimeout(() => {
            this.loading = false;
            this.confirmModal = false;
            this.isSuccess = true;
            this.titlesuccess = this.$i18n.t("saveSuccess");
            this.$store.dispatch("getShop")

            setTimeout(() => {
              this.isSuccess = false;
            }, 5000);
          }, 1000);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.form-control:disabled,
.form-control[readonly] {
  background-color: white;
}

.vue__time-picker input.display-time {
  width: 100%;
}

.vue__time-picker {
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Semi-transparent overlay */
  z-index: 100;
  /* Ensure it’s on top */
  cursor: not-allowed;
  /* Show a not-allowed cursor */
}
</style>
